// Render Prop
import React from 'react';
import { Formik, Form, Field, FormikHelpers as FormikActions } from 'formik';
// import axios from 'axios';

import {
  alert,
  alertSuccess,
  alertError,
  styledForm,
  styledInput,
  styledSubmit
} from '../../styles/forms';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
}

const PartnerContactForm = () => (
  <>
    <Formik
      initialValues={{ email: ''}}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { resetForm, setStatus }) => {
        // TODO - use axios here?
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'partner-contact', ...values })
        })
        .then(res => {
          setStatus(res.status)
          if (res.status === 200) {
            resetForm()
            setStatus({
              sent: true,
              msg: 'Thank you for your interest. Someone will contact you shortly.'
            })
          }
        })
        .catch(_err => {
          resetForm()
          setStatus({
            sent: false,
            msg: 'There was a problem submitting the form. Please contact sayhi@jihi.com for help if the problem persists.'
          })
        })
      }}
    >
      {({ isSubmitting, status }) => (
        <>
          <Form css={styledForm} data-netlify='true' name='partner-contact'>
            <Field
              name='form-name'
              type='hidden'
              value='partner-contact'
            />

            <Field
              name='email'
              type='email'
              required={true}
              css={styledInput}
              placeholder='Enter your email'
            />

            <button type='submit' disabled={isSubmitting} className='Button Button--primary' css={styledSubmit}>
              Submit
            </button>
          </Form>

          {status && status.msg && (
            <p
              css={[alert, status.sent ? alertSuccess : alertError]}
            >
              {status.msg}
            </p>
          )}
        </>
      )}
    </Formik>
  </>
);

export default PartnerContactForm;
