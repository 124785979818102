import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import SmartImage from '../components/SmartImage';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { Logo } from '../components/Logo';
import Pagination from '../components/Pagination';
import { PostCard } from '../components/PostCard';
import { InitiativeBlock } from '../components/InitiativeBlock';
import KlaviyoInlineForm from '../components/forms/KlaviyoInlineForm';
import PartnerContactForm from '../components/forms/PartnerContactForm';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  innerNarrow,
  outer,
  outerFull,
  PostFeed,
  Posts,
  Section,
  SectionContent,
  SectionContentColumn,
  SectionContentContent,
  SectionContentTitle,
  SectionIntro,
  SiteDescription,
  SiteHeader,
  SiteHeaderHero,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteHeaderStyles,
  SiteHeaderStylesBackground,
  outerBordered,
} from '../styles/shared';
import config from '../website-config';
import { PageContext } from './post';
export interface IndexProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    header: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    allMarkdownRemark: {
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const IndexPage: React.FC<IndexProps> = props => {
  const { width, height } = props.data.header.childImageSharp.fixed;
  const homepage = props.data.homepage.frontmatter;

  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper>
        <div
            // css={[outer, SiteHeader, SiteHeaderStyles, SiteHeaderStylesBackground]}
            css={[outer, SiteHeader, SiteHeaderStyles]}
            className="site-header-background"
            style={{
              // backgroundImage: `url('${homepage.hero_image.publicURL}')`
            }}
          >
          <div css={inner}>
            <SiteNav isHome />
            <SiteHeaderContent className="site-header-content">
              <SiteTitle className="site-title">
                {homepage.logo ? <Logo /> : config.title.toUpperCase()}
              </SiteTitle>
              <SiteDescription dangerouslySetInnerHTML={{ __html: homepage.hero_blurb }} />
            </SiteHeaderContent>
          </div>
        </div>

        <div css={outer}>
          {homepage.content_with_image.length > 0 && homepage.content_with_image.map((content_with_image, index) => {
            return (
              <Section css={inner} key={index}>
                <SectionContent>
                  <SectionContentColumn>
                    <SectionContentContent>
                      <SmartImage imageInfo={content_with_image.image} />
                    </SectionContentContent>
                  </SectionContentColumn>

                  <SectionContentColumn>
                    {/* TODO - this doesn't feel right because of the dangerously */}
                    {/* Use AST/REHYPE? https://www.gatsbyjs.com/plugins/gatsby-remark-component/ */}
                    <SectionContentContent dangerouslySetInnerHTML={{ __html: content_with_image.content }} />
                  </SectionContentColumn>
                </SectionContent>
              </Section>
            )
          })}
        </div>

        <div css={outerFull}>
          <Section css={inner}>
            <SectionIntro>
              <h2>Subscribe Today</h2>
              <p>Stay up to date! Get all the latest + greatest updates delivered straight to your inbox.</p>
            </SectionIntro>
            <SectionContent style={{padding: 0}}>
              <KlaviyoInlineForm formKey={config.klaviyoInlineForm} />
            </SectionContent>
          </Section>
        </div>

        <div id="initiatives">
          <div css={outer}>
            <Section>
              <SectionIntro>
                <h3>{homepage.initiatives_header}</h3>
              </SectionIntro>
            </Section>
          </div>

          {props.data.initiatives.edges.map((initiative, _index) => {
            // filter out drafts in production
            return (
              (initiative.node.frontmatter.draft !== true ||
                process.env.NODE_ENV !== 'production') && (
                <InitiativeBlock key={initiative.node.fields.slug} initiative={initiative.node} />
              )
            );
          })}
        </div>

        <main id="site-main" css={[SiteMain, outer]}>
          <div css={[inner, Posts]}>
            <div css={[PostFeed]}>
              {props.data.allMarkdownRemark.edges.map((post, index) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true ||
                    process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} large={index === 0} />
                  )
                );
              })}
            </div>
          </div>
        </main>

        {/* TODO - should we move our sections to children? */}
        {props.children}

        {props.pageContext.numPages > 1 && (
          <Pagination
            currentPage={props.pageContext.currentPage}
            numPages={props.pageContext.numPages}
          />
        )}

        <div css={[outerFull, outerBordered]}>
          <Section css={inner}>
            <SectionIntro>
              <h2>Interested in Becoming a Partner?</h2>
              <p>Drop us your email address and we'll be in touch</p>
            </SectionIntro>
            <SectionContent style={{padding: 0}}>
              <PartnerContactForm />
            </SectionContent>
          </Section>
        </div>

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    header: file(relativePath: { eq: "jihi-social-share.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    initiatives: allMarkdownRemark(
      limit: 2000
      sort: {fields: [frontmatter___date], order: ASC}
      filter: {fields: {template_key: {regex: "/initiative/"}}, frontmatter: {draft: {ne: true}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            partners_visible
            partners_header
            partners_aside
            homepage_excerpt: html(from: "homepage_excerpt")
            homepage_image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  base64
                  originalImg
                  originalName
                  srcSetWebp
                  srcWebp
                  tracedSVG
                }
              }
              extension
              publicURL
            }
            image_with_config {
              image_alt_text
              image_link
              image_link_target_blank
              image {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true } },
        fields: { template_key: { regex: "/blog/" }}
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }

    homepage: markdownRemark(fields: {template_key: {eq: "page.homepage"}}) {
      id
      frontmatter {
        form_component
        logo {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        # hero_image {
        #   extension
        #   publicURL
        #   childImageSharp {
        #     fluid(maxWidth: 1000) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
        hero_blurb: html(from: "hero_blurb")
        initiatives_header
        initiative
        content_with_image {
          content: html(from: "content")
          image_alt_text
          image_position
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 300) {
                base64
                originalImg
                originalName
                srcSetWebp
                srcWebp
                tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-large .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

export default IndexPage;
