import { Link } from 'gatsby';
import React from 'react';

import { PageContext } from '../templates/initiative';
import SmartImage from './SmartImage';
import Img from 'gatsby-image';

import {
  inner,
  outer,
  outerFull,
  Section,
  SectionContent,
  SectionContentColumn,
  SectionContentContent,
  SectionContentTitle,
  SectionIntro,
  SiteMain,
  outerBordered,
} from '../styles/shared';

export interface InitiativeBlockProps {
  initiative: PageContext;
}

export const InitiativeBlock: React.FC<InitiativeBlockProps> = ({ initiative }) => {
  const slug = initiative.fields.slug;
  const frontmatter = initiative.frontmatter;

  return (
    <>
      <div css={outer}>
        <Section css={inner}>
          <SectionContent>
            <SectionContentColumn>
              <SectionContentContent>
                <SmartImage imageInfo={frontmatter.homepage_image} />
              </SectionContentContent>
            </SectionContentColumn>

            <SectionContentColumn>
              <div>
                <SectionContentTitle>
                  { frontmatter.title }
                </SectionContentTitle>

                {/* TODO - this doesn't feel right because of the dangerously */}
                {/* Use AST/REHYPE? https://www.gatsbyjs.com/plugins/gatsby-remark-component/ */}
                <SectionContentContent>
                  <div dangerouslySetInnerHTML={{ __html: frontmatter.homepage_excerpt }}></div>

                  <Link to={slug} className="Button Button--primary Button--full">
                    Learn More
                  </Link>
                </SectionContentContent>
              </div>
            </SectionContentColumn>
          </SectionContent>
        </Section>
      </div>

      { !frontmatter.partners_visible && (
        <hr style={{margin: 0}} />
      )}

      { frontmatter.partners_visible && (
        <div css={[outerFull, outerBordered]}>
          <Section css={inner}>
            <SectionIntro>
              <h3>{frontmatter.partners_header}</h3>
              <p>{frontmatter.partners_aside}</p>
            </SectionIntro>

            <SectionContent>
              {frontmatter.image_with_config.map((image_w_config, index) => {
                return (
                  <SectionContentColumn key={index}>
                    <SectionContentContent>
                      { image_w_config.image_link.length > 0 && (
                        <a
                          href={image_w_config.image_link}
                          rel='noopener'
                          target={image_w_config.image_link_target_blank ? '_blank' : ''}
                        >
                          <SmartImage imageInfo={image_w_config.image} />
                        </a>
                      )}

                      { image_w_config.image_link.length === 0 && (
                        <SmartImage imageInfo={image_w_config.image} />
                      )}
                    </SectionContentContent>
                  </SectionContentColumn>
                );
              })}
            </SectionContent>
          </Section>
        </div>
      )}
    </>
  );
};
